@import url('https://fonts.googleapis.com/css2?family=Agdasima&family=Roboto&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}

body {
	background: #fce68f;
}

.App {
	max-width: 1000px;
	margin: 0 auto;
	padding: 1rem;
}

a, a:visited {
	color: #000000;
}

h1 {text-align: center; margin: 0 0 1rem;}

h2 {margin-bottom: 1rem;}
p.forum-down {text-align: center; margin: 0 0 1rem;}

.thread {
	color: #222222;
	background: #efefef;
	padding: .5rem;
	border-radius: .5rem;
	margin-bottom: 1rem;
}

.thread h2 {
	font-size: 1.25rem;
	margin-bottom: .5rem;
}

.thread h3 {
	font-size: 1rem;
	margin-bottom: .25rem;
	font-size: .75rem;
}

.thread .metadata div {
	font-size: .75rem;
	margin-bottom: .5rem;
}

.pages {
	margin-top: .5rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.pages a {
	display: block;
	background: #fdcb02;
	font-weight: bold;
	padding: .25rem;
	border-radius: .25rem;
	margin: 0 .25rem .25rem 0;
	font-size: .75rem;
}

.post {
	margin-bottom: 1rem;
}
.post .content {
	color: #222222;
	background: #efefef;
	padding: .5rem;
	border-radius: .5rem;
	margin: .25rem 0;
}

.post .content p {
	margin: 0 0 1rem;
}

.post .content blockquote {
	background: #e6e6e6;
	border: dotted 1px #aaaaaa;
	padding: .5rem;
	border-radius: .5rem;
	margin-bottom: .5rem;
}

.post .username, .post .created {
	font-size: .8rem;
	font-weight: bold;
}

.post .username {
	margin-left: 1rem;
}
.post .created {
	text-align: right;
	margin-right: 1rem;
}

.show-more {
	display: block;
	width: 100%;
	padding: .5rem;
}

@media screen and (min-width: 800px) {
	.thread .metadata {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}
	.thread .metadata div {
		text-align: center;
		margin-bottom: 0;
	}
	
}
